
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import Link from "next/link";
import SVG from "react-inlinesvg";
import { Card, CardBody, Spinner } from "reactstrap";
import { Routes } from "@basics/routes";
import BaseLayout from "@components/BaseLayout";
import ForwardingPage from "@components/common/ForwardingPage";
import HtmlContent from "@components/common/HtmlContent";
import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces";
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n";
import { useCurrentProgram } from "@services/pageHelpers";
import { routeWithParams } from "@services/routesHelper";
import { SINN_PROTOTYPE_CLIENT_CONFIG_USED } from "config";
const usedNamespaces: NamespaceShortcut[] = ["common", "program-operations"];
/**
 * this page is the front page of the app
 */
const FrontPage: React.FC = () => {
    const t = useDynamicTranslation();
    const { currentProgram, programRequest } = useCurrentProgram();
    if (SINN_PROTOTYPE_CLIENT_CONFIG_USED) {
        return <ForwardingPage targetUrl={Routes.Login}/>;
    }
    return <BaseLayout pageTitle={t("common", "page.index.title")} isFrontPage={true}>
    <div className="frontpage-content">
      <h1 role="heading" aria-level={2} className="frontpage-title">{t("common", "page.index.heading")}</h1>
      <span className="frontpage-subtitle">
        <HtmlContent content={t("common", "page.index.quote")}/>
      </span>
      <Card>
        <CardBody>
          <div className="d-flex gap-4">
            <SVG src={"/assets/img/uni-kassel/logo-green-office.svg"}/>
            <p className="frontpage-text"><HtmlContent content={t("common", "page.index.intro")}/></p>
          </div>
          {programRequest?.isLoading
            ? <Spinner />
            : currentProgram
                ? <Link href={routeWithParams(Routes.MarketOfProjects, { id: currentProgram.id })} className="btn btn-frontpage">
                <span className="title">{t("common", "page.index.buttonTitle")}</span>
                {t("common", "page.index.button")}
              </Link>
                : <div className="btn btn-frontpage">{t("program-operations", "noProgram")}</div>}
        </CardBody>
      </Card>
    </div>
  </BaseLayout>;
};
const __Next_Translate__Page__19324e3b567__ = withDynamicNamespaces(FrontPage, usedNamespaces);

    export default __appWithI18n(__Next_Translate__Page__19324e3b567__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  